<template>
  <div class="privitePolicyWrapper">
    <div class="privitePolicyContainer">
      <p class="headerTitle">Terms of Service</p>
      <section class="paragraph">
        The Boolv tools are provided by
        <a class="linkName" href="https://boolv.tech/#/" target="_blanck">

          Boolv.tech
        </a>
        Ltd. (<span class="fontBold">"Boolv"</span> or, <span class="fontBold">"us"</span>,
        <span class="fontBold">"our"</span> , <span class="fontBold">"we"</span>) for you to
        create quality videos using our tools accessible at the Boolv website located at
        <a class="linkName" href="https://boolv.tech/#/" target="_blanck">

          https://boolv.tech/#/
        </a>
        and through the designated Boolv apps for Android, iOS and Shopify (the Boolv website and apps will be
        collectively referred to as the <span class="fontBold">"Site"</span>
        ).
      </section>
      <section class="paragraph">
        These Terms of Service ( <span class="fontBold">"Terms"</span>) govern your access and use of the
        Site and all services available through the Site ( <span class="fontBold">"Services"</span>
        ). <span class="fontBold">"You""</span> means any registered Member, any adult user of the Site, or
        any parent or guardian of any minor whom you allow to use the Site and the Services, and for whom you will be
        held strictly responsible.
      </section>
      <section class="paragraph">
        If you are a minor, you must obtain the permission of your parent or guardian prior to using the Site or
        Services. If you do not obtain such permission, do not use the Site or Services.
      </section>
      <section class="paragraph">
        Please read these Terms carefully. These Terms govern your access to and use of the Site. By clicking on the
        button marked "I agree" you signify your assent to both these Terms and our Privacy Policy, which is available
        at
        <a class="linkName" href="https://boolv.tech/#/privacyPolicy" target="_blanck">
          Boolv.com/privacy-policy
        </a>
        . Changes may be made to these Terms from time to time. Your continued use of the Site or Services will be
        deemed acceptance to amended or updated terms. If you do not agree to any of these Terms, please do not use the
        Site or Services.
      </section>
      <p class="title">Limited License</p>
      <section class="paragraph">
        Boolv grants you a limited license to access and use the Site and Services subject to these Terms for your
        commercial business use only. You acknowledge that Boolv may, in its sole discretion and at any time,
        discontinue providing any part of the Site or the Services without notice.
      </section>
      <section class="paragraph">
        Use of the Services and access to the Site is void where prohibited. By using the Services, you represent and
        warrant that (a) all registration information you submit is truthful and accurate; (b) you will maintain the
        accuracy of such information; (c) you are 18 years of age or older, have your parent's or guardian's permission
        to enter into this agreement, or otherwise have the ability to form a binding contract; and (d) your use of the
        Site or Services does not violate any applicable law or regulation or any obligation you may have to a third
        party.
      </section>
      <section class="paragraph">
        You are welcome to link to the Site from your website, provided that your website does not imply any endorsement
        by or association with Boolv.
      </section>
      <p class="title">Account Registration</p>
      <section class="paragraph">
        We may allow you to register or otherwise use the Services through third party login services (such as Facebook
        Connect). Whether you register through third party login service or you create a Boolv account on the Site, all
        information provided must be accurate, current and complete. You shall have all responsibility for any
        inaccuracies in any information you provide to us, or in respect of your failure to keep such information
        up-to-date. If you choose to login or register through such third party services, you agree to the terms and
        conditions of all such third party services. You acknowledge that the use of third party services to login or
        register for the Site may provide us with access to certain information and data that you have provided to such
        third party service (according to the terms and conditions of such third party service) and you expressly agree
        to our use of such data or information in accordance with these Terms and Privacy Policy.
      </section>
      <section class="paragraph">
        If you do not use a third party login service you will create a Boolv account. Your account allows you to use
        the Services, subject to these Terms and our Privacy Policy. Boolv reserves the right to refuse to allow a user
        to open an account for any reason, or to limit the number of accounts a user can establish on the Site, all at
        its sole discretion. To create an account, you must select a username and password. Your username is your Boolv
        identity. You may not select a username that is used by someone else, and your username cannot be indecent, or
        otherwise offensive, or be used in any way that violates these Terms and common practices. You may not provide
        false information during the registration process.
      </section>
      <section class="paragraph">
        You should not reveal your password to other users. We will not ask you to reveal your password. If you forget
        your password, you can request to have a new password sent to your registered e-mail address. Your account is at
        risk if you let someone use it inappropriately and your account is subject to termination if you or anyone using
        your account violates these Terms. If your account is terminated, you may not open an account again without
        express permission.
      </section>
      <section class="paragraph">
        You agree to immediately notify Boolv of any unauthorized use of your Boolv account or password. You are fully
        and solely responsible for the security of your computer system and all activity on your account, even if such
        activities were not committed by you. Boolv will not be liable for any losses or damage arising from
        unauthorized use of your account or password, and you agree to indemnify and hold Boolv harmless for any
        improper or illegal use of your account, including any mail sent and any charges and taxes incurred, unless you
        have notified us via e-mail to
        <a href="mailto:support@boolvector.come" target="_blank" rel="noreferrer">
          support@boolvector.com
        </a>
        that your account has been compromised, and have requested us to block access to it. We do not police for, and
        cannot guarantee that we will learn of or prevent, any inappropriate use of the Site.
      </section>
      <section class="paragraph">
        Your Boolv Account on the Site may not be sold or otherwise transferred to another person or entity and is
        limited to a single user per Account, as further described below.
      </section>
      <section class="paragraph">
        If your use of the Site is on behalf of an entity, you represent and warrant that you have the authority to bind
        such entity to the present Terms of Service.
      </section>

      <p class="title">Termination of Account</p>

      <section class="paragraph">
        You agree that Boolv may for any reason, in its sole discretion and without notice, terminate your account, and
        remove from the Site any Content associated with your account. Grounds for such termination may include (i)
        extended periods of inactivity, (ii) violation of the letter or spirit of these Terms, (iii) fraudulent,
        harassing or abusive behavior, or (iv) behavior that is harmful to other users, third parties, or the business
        interests of Boolv.
      </section>
      <section class="paragraph">
        Termination of Account for any of the above mentioned reasons shall not entitle you to any refund.
      </section>
      <section class="paragraph">
        If Boolv believes, in its sole discretion, that a violation of these Terms or any illegal or inappropriate
        behavior has occurred, we may take any other corrective action we deem appropriate. We reserve the right to
        investigate suspected violations of these Terms or illegal and inappropriate behavior on the Site. We will fully
        cooperate with any law enforcement investigation or court order requesting or directing us to disclose the
        identity, behavior or Content of anyone believed to have violated these Terms or to have engaged in illegal
        behavior.
      </section>
      <section class="paragraph">
        If you want to terminate your and account subscription permanently, please log in and go to the "My Account page
        on and confirm the "Delete Account" (but you may not do so while your subscription is suspended).
      </section>
      <section class="paragraph">
        Any suspension or termination of your account shall not affect your obligations under these Terms (including but
        not limited to ownership, indemnification, any representations and warranties made by you, and limitation of
        liability), which by their sense and context are intended to survive such suspension or termination. On
        termination, you lose the right to access or use the Services.
      </section>

      <p class="title">Content</p>
      <section class="paragraph">
        Certain types of content may be made available through the Site or the Services.
        <span class="fontBold">"Content"</span> as used in these Terms means, collectively, all content on or
        made available or created on the Site or through the Services by you including but not limited to the videos and
        the music incorporated in it.
      </section>
      <section class="paragraph">
        You are fully and solely responsible for any Content you create, submit or post to the Site or any other
        platform, including all Content that you include in any video or use to create your videos. You represent and
        warrant that any such submitted Content complies with all applicable law, that you have all necessary rights to
        submit any such Content or to use, display and distribute any submitted Content to create any video ad and that
        no such Content (or modifications you may make to such Content) infringes or violates any third party
        intellectual property rights, privacy or publicity rights, or moral rights. If you submit to Boolv any Content
        from an account at a third party (such as from a Facebook account), you represent and warrant that you have all
        rights necessary to submit such Content for use with the Boolv services. If you distribute (or allow for the
        distribution of) any Content through any third party (such as through a social media account such as Facebook),
        you represent and warrant that you have all rights necessary to distribute (or allow for the distribution of)
        such Content. Boolv has no obligation to accept, display, review or maintain any Content. Moreover, Boolv
        reserves the right to remove and permanently delete any Content, including any Content submitted or modified by
        any user, without notice and for any reason. Moreover, if Boolv reasonably believes that any Content distributed
        through a third party (such as through a social media account such as Facebook) could violate any provision of
        these Terms, Boolv may contact such third party in order to inform such third party of the relevant violation.
        WE DO NOT ENDORSE ANY CONTENT OR ANY OPINION, RECOMMENDATION, OR ADVICE EXPRESSED IN ANY CONTENT, AND WE
        EXPRESSLY DISCLAIM ANY AND ALL LIABILITY IN CONNECTION WITH CONTENT.
      </section>
      <section class="paragraph">
        Boolv may choose at its sole discretion to monitor Content for inappropriate or illegal behavior, including
        through automatic means; provided, however, that Boolv reserves the right to treat user Content as content
        stored at the direction of users for which Boolv will not exercise editorial control except when violations are
        directly brought to Boolv's attention. Boolv has the right to edit, delete, distort, modify or move user Content
        from the Site without notice for any reason at any time. Additionally, Boolv has the right to refuse to
        transmit, email, post or broadcast any user Content (including to refuse to allow a user to post or distribute
        such Content through a third party) without notice for any reason at any time.
      </section>
      <section class="paragraph">
        Content on the Site comes from a variety of sources. You understand that Boolv is not responsible for the
        accuracy, usefulness, safety or intellectual property rights of or relating to this Content. Although users must
        agree to these Terms, it is possible that other users (including unauthorized users) may post or transmit
        offensive or obscene materials that you may be involuntarily exposed to such offensive or obscene materials, and
        you hereby agree to waive, and do waive, any legal or equitable rights or remedies you have or may have against
        us with respect to thereto. WE DISCLAIM ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR
        OMISSIONS OF OTHER MEMBERS OR USERS (INCLUDING UNAUTHORIZED USERS), WHETHER SUCH ACTS OR OMISSIONS OCCUR DURING
        THE USE OF THE SERVICE OR OTHERWISE.
      </section>
      <section class="paragraph">
        Content created will be only viewable by you unless you decide to share it publicly on the Site or any other
        platform. PLEASE CAREFULLY SELECT THE TYPE OF INFORMATION THAT YOU INCLUDE IN ANY CONTENT, AND ESPECIALLY ANY
        CONTENT THAT YOU SHARE PUBLICLY. Do not post publicly any private information or information you do not wish to
        be seen by others, or which you do not want to be shared or edited by others. Please note that once you provide
        a third party with a link to any Content, such third party may freely share that link with additional third
        parties. As such, please carefully select the individuals to whom you choose to provide with links to any
        Content. We are not responsible for the use by third parties of any personal information that you disclose on
        the Service or through any Content.
      </section>
      <section class="paragraph">
        You irrevocably waive all claims that you may have against any Boolv (including its affiliates, employees,
        contractors, users and customers) or any third party entities or individuals for the use or distribution of any
        of your Content including all claims in respect of intellectual property rights, privacy rights, publicity
        rights or moral rights. You shall fully indemnify Boolv against any such claims that you may bring.
      </section>
      <section class="paragraph">
        Please note that the following musical works are no longer available within our Services (as of November 2018):
        <b />
        Alive; Are You Ready For It; Better All The Time; Blue Danube Waltz; Bout That; Action; Fiesta En El Rancho; Go
        Get The Money; Head Over Heels; How Long; Il Trovatore; Keeping Me Alive; Moonlight Sonata; The Breach; The
        Theory Of Light; Tonight (live Forever); Trumpet Tune; While The City Sleeps; Gymnopedie No. 1.
      </section>

      <p class="title">Content and Use Restrictions</p>
      <section class="paragraph">
        You may download videos that you create on the Site or through the Services, and use them in the following ways:
      </section>
      <ul>
        <li class="listItem">
          Post them at online locations;</li>
        <li class="listItem">
          Use them for as long as you wish, even after your subscription to the Services expires;
        </li>
        <li class="listItem">
          Use as a background video on your website or as a social page cover</li>

        <li class="listItem">
          If you are a Pro, Pro-Lite, Agency or special custom plan user, then you may Assign or resell your rights to
          use the videos, to someone else (in which case your own rights to use the videos expire, and the recipient
          obtains those rights).
        </li>
        <section class="paragraph">
          You may not, by yourself or through others, use videos that you create on the Site or through the Services in
          any of the following ways:
        </section>
        <li class="listItem">
          Broadcast, display or perform the videos on television or cinema;</li>
        <li class="listItem">
          Edit, manipulate, add, remove or otherwise change in any way whatsoever a video that you've downloaded from
          the Services (including, but not limited to, the images, music or texts in the video);
        </li>
        <li class="listItem">
          Extract content from the video to use or distribute it separately.</li>
        <li class="listItem">
          Provide (sub-license) the video, or any component or portion thereof (including, but not limited to, the
          images, music or texts in it), for others (including entities under common control, subsidiaries, affiliates
          of your organization or the like) to use or reuse (including, but not limited to reuse as stock, archive or
          library footage);
        </li>
        <li class="listItem">
          Combine the video with another audiovisual piece.</li>

        <li class="listItem">
          Create, download or use the video as "raw footage" that do not contain text captions throughout at least 50%
          of said "raw footage" and a music track, or use or resell the produced video as stock content in any way or
          form, online or elsewhere.
        </li>
        <li class="listItem">
          Create, download and use "raw music", that is, use a music track without the accompanying video footage that
          includes both text captions and the music track, thought at least 50% of said "raw music".
        </li>
      </ul>

      <section class="paragraph">
        You may not use musical elements that we provide on the Services in order to create episodic series productions
        or themes, namely television programming or motion picture cinematographic movies.
      </section>
      <section class="paragraph">
        You may not use any content marked as "EDITORIAL" for any commercial, Boolvtional, advertorial, endorsement,
        advertising, sponsorship, marketing or merchandising purpose for any product, service, and brand or for any
        other commercial use, or in conjunction with elections or donations. Use of EDITORIAL content for commercial
        purposes as provided herein may expose you to legal liability by the right holders of such content and is a
        violation of these Terms.
      </section>
      <section class="paragraph">
        You may not use images or graphical elements we provide on the Service in any of the following manners:
      </section>

      <ul>
        <li class="listItem">
          In any manner other than incorporating them into the content you create on the Services;
        </li>
        <li class="listItem">
          Portraying any person depicted therein (a "Model") in a way that a reasonable person would find offensive,
          including but not limited to depicting a Model: a) in connection with pornography, "adult videos", adult
          entertainment venues, escort services, dating services, or the like; b) in connection with the advertisement
          or Boolvtion of tobacco products; c) as endorsing a political party, candidate, elected official, or opinion;
          d) as suffering from, or medicating for, a physical or mental ailment; or e) engaging in immoral or criminal
          activities;
        </li>
        <li class="listItem">
          As a trademark, service mark, or logo;</li>
        <li class="listItem">
          Implying endorsement of, or affiliation with, your product by people, brands or logos on the image.
        </li>
      </ul>

      <section class="paragraph">
        Without limiting the foregoing, you agree that you will not create, transmit, submit or post any Content
        (including any comments, or modifications to or mashups of any Content) that: (1) Violates the legal rights of
        others, including defaming, abuse, stalking or threatening users; (2) Infringes (or results in the infringement
        of) the intellectual property rights, moral rights or other rights of any third party; (3) Is (or you reasonably
        believe or should reasonably believe to be) illegal, fraudulent, or unauthorized, or in furtherance of any
        illegal, counterfeiting, fraudulent, pirating, unauthorized, or violent activity, or that involves (or you
        reasonably believe or should reasonably believe to involve) any stolen, illegal, counterfeit, fraudulent,
        pirated, or unauthorized material; (4) does not comply with all applicable laws, rules and regulations; (5)
        Publishes falsehoods or misrepresentations that may damage us or any third party; (6) Involves gambling
        activities, or the payment or acceptance of payments relating to gambling activities; (7) Imposes an
        unreasonably or disproportionately large load on our infrastructure; or (8) Posts, stores, transmits, offers, or
        solicits anything that contains the following, or that you know contains links to the following or to locations
        that in turn contain links to the following: (a) material that we determine to be offensive (including material
        Boolvting or glorifying hate, violence, bigotry, or any entity (past or present) principally dedicated to such
        causes or items associated with such an entity), (b) material that is racially or ethnically insensitive,
        material that is defamatory, harassing or threatening, (c) pornography or obscene material, (d) any virus, worm,
        trojan horse, or other harmful or disruptive component or (e) anything that encourages conduct that would be
        considered a criminal offense, give rise to civil liability, violate any law or regulation or is otherwise
        inappropriate or offensive.
      </section>
      <section class="paragraph">
        You may not do or attempt to do any of the following: (1) Attempt to decipher, decompile, disassemble, or
        reverse-engineer any of the software used to provide the Services or the Site without our prior written
        authorization, including framing or mirroring any part of the Site; and (2) Circumvent, disable, or otherwise
        interfere with security-related features of the Services or the Site or features that prevent or restrict use or
        copying of any Content; (3) Use any robot, spider, site search or retrieval application, or any other manual or
        automatic device or process to retrieve, index, data-mine, or in any way reproduce or circumvent the
        navigational structure or presentation of the Site; (4) Use or access another user’s account or password without
        permission; (5) Use the Site or Content thereon in any manner not permitted by these Terms, (6) post, distribute
        or edit any Content or links in violation of the requests or wishes of the individual that provided you with
        such Content or links.
      </section>
      <section class="paragraph">
        You may not use the videos that you create on the Site or through the Services in any manner that suggests that
        such video or similar videos can be obtained elsewhere.
      </section>
      <section class="paragraph">
        YOU MAY NOT USE THE SERVICES IN AN ATTEMPT, OR WITH A VIEW TO DEVELOP, OR CREATE, OR PERMIT OTHERS TO DEVELOP OR
        CREATE, A PRODUCT OR SERVICE SIMILAR TO OR COMPETITIVE WITH THE SERVICES.
      </section>

      <p class="title">ntellectual Property</p>
      <section class="paragraph">
        Boolv and its licensors own the Site, including all worldwide intellectual property rights in the Site and
        Services, and the trademarks, service marks, and logos contained therein. Except as expressly permitted herein,
        you may not copy, further develop, reproduce, republish, modify, alter download, post, broadcast, transmit or
        otherwise use the Site, Content or the Services. You will not remove, alter or conceal any copyright, trademark,
        service mark or other proprietary rights notices incorporated in the Site, Content or Services. All trademarks
        are trademarks or registered trademarks of their respective owners. Nothing in these Terms grants you any right
        to use any trademark, service mark, logo, or trade name of Boolv or any third party.
      </section>

      <section class="paragraph">
        You retain all right, title and interest in any Content of yours that you submit to Boolv. If any third party
        Content is included in any video you create, you receive no rights in such Content, save the limited right to
        distribute such Content as embedded in your video, subject to the terms and conditions of these Terms.
      </section>

      <section class="paragraph">
        By creating, submitting or posting any Content you grant Boolv and its successors a worldwide, non-exclusive,
        royalty-free, perpetual, sublicensable and transferable license to use, copy, distribute, transmit, display, and
        perform such Content on, through or in connection with the Site or Services for any purpose and in any medium,
        including without limitation, for commercially Boolvting the Site and Services, as well as any other
        commercially available items on the Site.
      </section>

      <p class="title">Copyright Policy</p>
      <section class="paragraph">
        We respect the intellectual property rights of others and request our users to do the same. This section
        describes our copyright policy.
      </section>

      <p class="smallTitle">Alleged Infringement Notice</p>
      <section class="paragraph">
        If a person believes that certain content, which appears on or through the Services, infringes copyright that
        that person owns or represent, they may send our designated agent ("Agent") a written notification, stating the
        location of the work claimed to be infringing. Upon their notification and subject to applicable copyright laws,
        we may remove or disable access to any such content.
      </section>
      <section class="paragraph">
        To be effective, the notification of claimed infringement must be a written communication provided to our Agent
        that includes substantially the following:
      </section>
      <ul>
        <li class="listItem">
          A physical or electronic signature of the person authorized to act on behalf of the owner of the right that is
          allegedly infringed;
        </li>
        <li class="listItem">
          Identification of the work and the pertinent exclusive legal right claimed to be infringed, or if multiple
          works or legal rights are covered by a single notification, a representative list of such elements;
        </li>
        <li class="listItem">
          Identification of the material that is claimed to infringe or to be the subject of infringing activity and the
          access to which is to be disabled, and information reasonably sufficient to permit us to locate the material,
          including the precise location on the Services where they discovered the work claimed to be infringing;
        </li>
        <li class="listItem">
          Information reasonably sufficient to permit us to contact them, such as an address, telephone number, and, if
          available, an electronic mail address at which you may be contacted;
        </li>
        <li class="listItem">
          A statement that they have a good faith belief that the use of the material, in the manner complained of, is
          not authorized by the copyright owner, its agent, or the law;
        </li>
        <li class="listItem">
          A statement that the information in the notification is accurate, and under penalty of perjury, that they are
          authorized to act on behalf of the owner of copyright that is allegedly infringed.
        </li>
      </ul>
      <section class="paragraph">
        After receiving the communication, we may ask person who submitted the claimed infringement to provide further
        or supplemental information, prior to removing any content on the Services, as we deem necessary to comply with
        applicable law. We may also provide the user who uploaded the allegedly infringing content, with the contact
        details of person who submitted the claimed infringement, in order for the former to be able to contact the
        latter and challenge the claim.
      </section>

      <p class="smallTitle">Counter Notification</p>
      <section class="paragraph">
        If we've removed material that a user of the Services submitted for posting, pursuant to a notification of
        claimed infringement that we received, then that user has an opportunity to respond to the notification and our
        takedown by submitting a counter-notification to our Agent. To be effective, the counter notification must be a
        written communication provided to our Agent that includes substantially the following:
      </section>

      <ul>
        <li class="listItem">The user's physical or electronic signature;</li>
        <li class="listItem">
          Identification of the work and the pertinent exclusive legal right claimed to be infringed, or if multiple
          works or legal rights are covered by a single notification, a representative list of such elements;
        </li>
        <li class="listItem">
          Identification of the material to which access has been disabled and the location at which the material
          appeared before access to it was disabled;
        </li>
        <li class="listItem">
          A statement, under penalty of perjury, that the users has a good faith belief that the material was disabled
          as a result of mistake or misidentification of the material to be disabled;
        </li>
        <li class="listItem">
          User's name, address, and telephone number, and a statement that the user consents to the jurisdiction of the
          competent courts in any judicial district in which their address is located or in which they may be found, and
          that they will accept service of process from the person who provided notification or an agent of such person.
        </li>
      </ul>
      <section class="paragraph">
        After receipt of a counter notification, we will provide the person who submitted the claimed infringement
        notification, with a copy of the counter notification.
      </section>

      <section class="paragraph">
        We may then repost the removed material and cease disabling access to it within 10 to 14 business days following
        receipt of the counter notice, unless our Agent first receives notice from the person who notified us of the
        claimed infringement that such person has filed an action seeking a court order to restrain such user from
        engaging in infringing activity relating to the material on the Services.
      </section>

      <p class="smallTitle">Repeat Infringements</p>
      <section class="paragraph">
        We may deny or cancel any instance of your use of the Services, or terminate your user account altogether, if we
        determine, at our sole discretion, that you are a repeat infringer. A repeat infringer is a user who has been
        notified of infringing activity more than twice or a user whose uploaded material was removed from the Services
        more than twice.
      </section>
      <section class="paragraph">
        We may decide, at our sole discretion, that a sufficient reason exists for the immediate termination of your
        account for any reason, at any time. In these cases we may terminate your account immediately.
      </section>

      <p class="smallTitle">Misrepresentations</p>

      <section class="paragraph">
        Any person who knowingly materially misrepresents that: (1) certain material is infringing, or (2) certain
        material was removed or disabled by mistake or misidentification – may be liable for any damages, including
        costs and attorneys' fees, that we may incur, if we are injured by such misrepresentation, as the result of our
        reliance upon such misrepresentation in removing or disabling access to the material claimed to be infringing.
      </section>

      <p class="smallTitle">Disclaimers & Disclaimer of Warranty</p>
      <section class="paragraph">
        Your use of the Site, Content and Services is at your sole discretion and risk. The Site, Content and Services,
        and any product made available through the foregoing, are provided on an AS IS and AS AVAILABLE basis without
        warranties of any kind. WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY,
        RELATING TO THE SITE, CONTENT, AND SERVICES, OR WITH RESPECT TO ANY PRODUCT MADE AVAILABLE THROUGH THE
        FOREGOING, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. WE DISCLAIM ANY
        WARRANTIES, EXPRESS OR IMPLIED, (I) REGARDING THE SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF
        THE SITE, CONTENT AND SERVICES; OR (II) THAT THE SITE AND SERVICES WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE
        CORRECTED; OR (III) REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF
        ANY INFORMATION PROVIDED BY THE SITE AND SERVICES. We are not responsible for any incorrect or inaccurate
        Content posted on the Site or in connection with the Services, or transmitted by any User, whether by users of
        the Services or by any of the equipment or programming associated with the Services. We take no responsibility
        for third party advertisements which are posted on this Site or through the Services, nor does it take any
        responsibility for the goods or services provided by its advertisers. No advice or information, whether oral or
        written, obtained by you from us, shall create any warranty not expressly stated in these Terms. If you choose
        to rely on such information, you do so solely at your own risk. Some states or jurisdictions do not allow the
        exclusion of certain warranties. Accordingly, some of the above exclusions may not apply to you. Check your
        local laws for any restrictions or limitations regarding the exclusion of implied warranties. PRIOR TO UPLOADING
        ANY CONTENT TO THE SITE, YOU SHOULD ENSURE THAT YOU FULLY UNDERSTAND HOW THIRD PARTIES MAY ACCESS AND SHARE,
        SUCH CONTENT. IF YOU DO NOT FULLY UNDERSTAND HOW THIRD PARTIES MAY ACCESS AND SHARE CONTENT, DO NOT UPLOAD
        CONTENT. YOU SHALL NOT HAVE ANY CLAIMS AGAINST BOOLV, AND YOU HEREBY IRREVOCABLY WAIVE ALL CLAIMS AGAINST BOOLV,
        IN RESPECT OF THE USE, EDITING, MODIFICATION OR DISTRIBUTION OF ANY CONTENT (A) DESIGNATED AS “PUBLIC” ON THE
        SITE OR (B) IN RESPECT OF WHICH YOU HAVE PROVIDED THIRD PARTIES WITH A LINK TO SUCH CONTENT. BOOLV DOES NOT
        ASSUME ANY RESPONSIBILITY WITH RESPECT TO WHETHER USER GENERATED CONTENT INFRINGES THE INTELLECTUAL PROPERTY,
        MORAL, PRIVACY OR PUBLICITY RIGHTS OF ANY THIRD PARTY.
      </section>
      <section class="paragraph">
        Boolv is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of
        any Content or information that you provide, except to the extent required by applicable law.
      </section>

      <p class="title">Limitation of Liability</p>

      <section class="paragraph">
        We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or
        transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any
        Content or Services. We are not responsible for any problems or technical malfunction of any telephone network
        or lines, computer online systems, servers or providers, computer equipment, software, failure of any email due
        to technical problems or traffic congestion on the Internet or on any of the Site or Services or combination
        thereof, including any injury or damage to users or to any person's computer related to or resulting from
        participation or downloading materials in connection with the Site or Services. Under no circumstances shall we
        be responsible for any loss or damage, including personal injury or death, resulting from use of the Site or
        Services, from any Content posted on or through the Site or Services, or from the conduct of any Users of the
        Site or Services, whether online or offline.
      </section>
      <section class="paragraph">
        IN NO EVENT SHALL WE OR ANY OF OUR OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU FOR ANY DAMAGES
        WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES,
        ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE, CONTENT AND SERVICES, INCLUDING BUT NOT LIMITED TO
        THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH THE SITE OR THE SERVICES,
        WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        EXCEPT WITH RESPECT TO THE PURCHASE OF PHYSICAL GOODS, THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE
        FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL OUR CUMULATIVE LIABILITY TO
        YOU EXCEED U.S. $100. YOUR SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY PURCHASED GOODS WILL BE THE REFUND BY
        BOOLV OF THE AMOUNT YOU PAID FOR SUCH PRODUCT.
      </section>

      <p class="title">Linked Content</p>
      <section class="paragraph">
        Portions of the Site (including, without limitation, advertisements) may involve linking to or using web sites
        belonging to third parties. The Site may also provide you with links to access the Sites of third party vendors
        or retailers including, without limitation, for the purpose of reviewing or purchasing items. We have no control
        over third-party sites, and all use of third-party sites is at your own risk. Additionally, Boolv cannot accept
        responsibility for any payments processed or submitted through such third party sites, or for the privacy
        policies of any such sites. Boolv is not responsible for content or services available by means of such sites.
        Boolv does not endorse any products or services offered by third parties and we urge our users to exercise
        caution in using third-party sites.
      </section>
      <section class="paragraph">
        Boolv may allow you to purchase physical goods from third parties. Boolv has no responsibility or liability in
        respect of the provision of goods or services from any third party.
      </section>

      <p class="title">Indemnification</p>
      <section class="paragraph">
        You agree to indemnify, defend, and hold harmless Boolv and our respective employees, directors, officers,
        subcontractors and agents of each, against any and all claims, damages, or costs or expenses (including
        attorneys' fees) that arise directly or indirectly from: (a) breach of these Terms by you or anyone using your
        computer or password; (b) any claim, loss or damage experienced from your use or attempted use of the Site or
        the Services, including any message or Content you transmit through the Site or Services; (c) your violation of
        any law or regulation; (d) your infringement of any right of any third party; and (e) any other matter for which
        you are responsible hereunder or under law.
      </section>

      <p class="title">Online Application Stores</p>
      <section class="paragraph">
        Your use of the Services through our smartphone apps may be subject to additional third party terms and
        conditions that govern that application marketplace from which you downloaded the App, such as AppStore or
        Google Play. Such third parties are not responsible for providing maintenance and support services with respect
        to the Site.
      </section>

      <section class="paragraph">
        The following terms apply if you downloaded the Site’s iOS app from Apple's App Store. You and us agree and
        acknowledge as follows:
      </section>
      <section class="paragraph">
        These Terms are concluded between yourself and us, and not with Apple Inc. ("Apple"). Apple is not responsible
        for the Services. In the event of a conflict between these Terms and the App Store Terms of Service then the App
        Store Terms of Service will prevail, solely with respect to the conflicting provisions.
      </section>
      <section class="paragraph">
        The license granted to you for the Services’ app is limited to a non-transferrable license to use the app on any
        iOS Products that you own or control, and as permitted by the Usage Rules set forth in the App Store Terms of
        Service, except that such App may be accessed, acquired, and used by other accounts associated with the
        purchaser via Family Sharing.
      </section>
      <section class="paragraph">
        In the event of a failure to conform to any applicable warranty (if any warranty is applicable), you may notify
        Apple, and Apple will refund the purchase price for the smartphone app to you (if you paid any). Apple has no
        other warranty obligation whatsoever with respect to the app, and any other claims, losses, liabilities,
        damages, costs or expenses attributable to any failure to conform to any warranty, will not be at Apple’s
        responsibility. Apple is not responsible for addressing any claims by you or any third party relating to the
        Services or your possession and/or use of the Services, including (i) product liability claims, (ii) any claim
        that the Services fails to conform to any applicable legal or regulatory requirement, and (iii) claims arising
        under consumer protection or similar legislation.
      </section>

      <section class="paragraph">
        In the event of any third party claim that the app or your possession and use of the app infringes that third
        party’s IP Rights, Apple will not be responsible for the investigation, defense, settlement and discharge of any
        such infringement claim.
      </section>

      <section class="paragraph">
        You must comply with applicable third party terms of agreement when using the app (e.g. you must not be in
        violation of your wireless data services agreement when you use the app). Apple and Apple’s subsidiaries are
        third party beneficiaries of these Terms. Upon your acceptance of these Terms, Apple will have the right (and
        will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary
        thereof.
      </section>

      <section class="paragraph">
        You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government
        embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you
        are not listed on any U.S. Government list of prohibited or restricted parties.
      </section>

      <p class="title">Miscellaneous</p>
      <section class="paragraph">
        These Terms shall be governed by the laws of the State of New York exclusive of its choice of law rules, and
        without regard to the United Nations Convention on the International Sales of Goods. Your conduct may also be
        subject to other local, state, and national laws. Any action to be brought in connection with these Terms or the
        Site shall be brought exclusively in the state or federal courts in the State, City, and County of New York,
        United States of America, and you irrevocably consent to their jurisdiction. In any action to enforce these
        Terms, the prevailing party will be entitled to costs and attorneys' fees. Any cause of action against Boolv
        must be brought within one (1) year of the date such cause of action arose. In the event that any provision of
        these Terms is held to be unenforceable, such provision shall be replaced with an enforceable provision which
        most closely achieves the effect of the original provision, and the remaining terms of these Terms shall remain
        in full force and effect. Nothing in these Terms creates any agency, employment, joint venture, or partnership
        relationship between you and Boolv or enables you to act on behalf of Boolv. Except as may be expressly stated
        in these Terms, these Terms constitute the entire agreement between us and you pertaining to the subject matter
        hereof, and any and all other agreements existing between us relating thereto are hereby canceled.
      </section>
      <section class="paragraph">
        We may at any time, without prior notice: (a) cancel any user account, or limit any user's access to all or
        portions of the Site; (b) change or eliminate any component of the Site; (c) impose, remove, or change any fees
        or charges for use of the Site or any feature thereof (including, without limitation, by changing the manner in
        which fees are calculated); and (d) assign any and all of our rights under these Terms to any other entity.
        These Terms will bind and inure to the benefit of each party's permitted successors and assigns. We may elect to
        keep archives of all or parts of the Site, but we cannot guarantee that anything available on the Site, or any
        records or information relating to the Site, will be archived, or that any archives will be preserved or made
        publicly available. We may delete any such information without notice, at our sole discretion.
      </section>
      <section class="paragraph">
        Nothing contained in these terms shall be construed to limit the actions or remedies available to us with
        respect to any prohibited activity or conduct. Non-enforcement of any term of these Terms does not constitute
        consent or waiver, and we reserve the right to enforce such term at our sole discretion. No waiver of any breach
        or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. These
        Terms are for the benefit of Boolv (and respective assignees), and each shall have the right to assert and
        enforce its provisions against you directly on its own behalf. These Terms supersede any prior agreements with
        regard to your use of the Site.
      </section>
      <section class="paragraph">Last Updated: July 10, 2022</section>
    </div>
  </div>
</template>
<script setup>
import store from '../../store'
window.addEventListener("scroll", () => {
  store.dispatch('closeSide', { isCollapse: true })
});
</script>
<style scoped lang="less">
.privitePolicyWrapper {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  font-family: 'Helvetica Neue';
  text-align: left;
}

.privitePolicyContainer {
  max-width: 958px;
}

.headerTitle {
  font-weight: 500;
  font-size: 36px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 36px;
}

.paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
  padding-bottom: 24px;
}

.title {
  font-weight: 500;
  font-size: 26px;
  line-height: 20px;
  color: #000000;
  padding-bottom: 24px;
}

.smallTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  padding-bottom: 12px;
}

.table {

  tr,
  td,
  th {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 10px;
  }
}

.anchorPoint {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  color: #875eff;
  padding-bottom: 36px;
}

.linkName {
  color: #875eff;
}

.fontBold {
  font-weight: 500;
}

.listItem {
  padding-bottom: 14px;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
}

.listItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

.subListItem {
  text-indent: 1.5rem;
  // padding-top: 10px;
}

.subListItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

a {
  color: #875eff;

  &:hover {
    color: #a987ff;
  }
}

ul {
  list-style: none;
}
</style>